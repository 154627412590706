import { useQuery } from "@tanstack/react-query";
import { api } from "~/auth/fetch";
import { API } from "~/config/api";
import { QueryKey } from "~/constants";
import type { Cluster } from "~/types";

const getCluster = (clusterName: string) => {
  return api
    .get(`api/v1/clusters/${clusterName}`, { prefixUrl: API.IDP.url })
    .json<Cluster>();
};

export const useCluster = (clusterName: string) => {
  return useQuery({
    queryKey: [QueryKey.Cluster, clusterName],
    queryFn: () => getCluster(clusterName),
    enabled: !!clusterName,
  });
};
