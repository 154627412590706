import { faAtom, faHome } from "@fortawesome/pro-regular-svg-icons";
import { Nav } from "@intility/bifrost-react";
import { Link, NavLink, useNavigate, useSearchParams } from "react-router-dom";
import styles from "./navigation.module.css";

import { useAccount } from "@azure/msal-react";
import { faCircleInfo } from "@fortawesome/pro-regular-svg-icons";
import { faHandWave, faSignOutAlt } from "@fortawesome/pro-solid-svg-icons";
import cpmLogo from "~/assets/cpm-logo.svg";
import { logout } from "~/auth/instance";
import { useProfilePicture } from "~/api/getProfilePicture";

export default function Navigation({ children }: React.PropsWithChildren) {
  const navigate = useNavigate();
  const [URLSearch] = useSearchParams();
  const account = useAccount();

  const { data: profilePicture } = useProfilePicture();

  return (
    <Nav
      logo={
        <Link to="/home" className={styles.logo}>
          <img src={cpmLogo} alt="Developer Platform Logo" />
          <span>Developer Platform</span>
        </Link>
      }
      top={
        <Nav.Group
          name={
            <img
              src={profilePicture}
              alt="profile"
              className="bf-nav-profile"
            />
          }
        >
          <Nav.Item icon={faHandWave} hideCircle>
            Welcome, {account?.name?.split(" ")[0]}
          </Nav.Item>
          <hr />
          <button onClick={() => logout()}>
            <Nav.Item hideCircle icon={faSignOutAlt}>
              Sign out
            </Nav.Item>
          </button>
        </Nav.Group>
      }
      side={
        <>
          <NavLink to="/home" end>
            <Nav.Item icon={faHome}>Home</Nav.Item>
          </NavLink>
          <NavLink to="/clusters" end>
            <Nav.Item icon={faAtom}>Clusters</Nav.Item>
          </NavLink>
          <Nav.Group icon={faCircleInfo} name="Help center">
            <a
              href="/helpcenter?tab=guides"
              className={URLSearch.has("tab", "guides") ? "active" : ""}
              onClick={(e) => {
                e.preventDefault();
                navigate("helpcenter?tab=guides");
              }}
            >
              <Nav.Item>Guides</Nav.Item>
            </a>
            <a
              href="/helpcenter?tab=support"
              className={URLSearch.has("tab", "support") ? "active" : ""}
              onClick={(e) => {
                e.preventDefault();
                navigate("helpcenter?tab=support");
              }}
            >
              <Nav.Item>Support</Nav.Item>
            </a>
          </Nav.Group>
        </>
      }
    >
      {children}
    </Nav>
  );
}
