import {
  Button,
  Grid,
  Inline,
  Input,
  Spinner,
  useFloatingMessage,
} from "@intility/bifrost-react";

import { useContext, useState } from "react";
import { ModalContext } from "~/context/ModalContext";
import { useDeleteCluster } from "../../api/deleteCluster";
import styles from "./deleteModal.module.css";
import type { Cluster } from "~/types";
import { TitledFloatingMessage } from "~/components/TitledFloatingMessage";
import { useNavigate } from "react-router-dom";

interface DeleteModalProps {
  cluster: Cluster;
}

export const DeleteModal = ({ cluster }: DeleteModalProps) => {
  const deleteCluster = useDeleteCluster();
  const navigate = useNavigate();

  const [inputString, setInputString] = useState("");

  const { handleModal } = useContext(ModalContext);
  const { showFloatingMessage } = useFloatingMessage();

  const clusterName = cluster.name;

  const handleDelete = () => {
    deleteCluster.mutate(clusterName, {
      onError: () => {
        showFloatingMessage(<ErrorMessage clusterName={clusterName} />, {
          state: "warning",
          noIcon: true,
        });
      },
      onSuccess: () => {
        showFloatingMessage(<SuccessMessage clusterName={clusterName} />, {
          state: "success",
          noIcon: true,
        });
      },
      onSettled: () => {
        closeModal();
        navigate("/clusters");
      },
    });
  };

  const closeModal = () => {
    handleModal({});
  };

  return (
    <Grid className={styles.modal}>
      <span>
        Are you sure you want to delete{" "}
        <span className={styles.clusterName}>{clusterName}</span>?
      </span>
      <Input
        className={styles.confirmationInput}
        label="Write cluster name to delete"
        placeholder="Cluster name"
        value={inputString}
        onChange={(e) => setInputString(e.target.value)}
      />
      <Inline>
        <Inline.Separator />
        <Button
          state="neutral"
          className={styles.modalButton}
          onClick={() => {
            closeModal();
          }}
        >
          Cancel
        </Button>

        <Button
          className={styles.modalButton}
          variant="filled"
          state="alert"
          disabled={inputString !== clusterName || deleteCluster.isPending}
          onClick={() => {
            handleDelete();
          }}
        >
          {deleteCluster.isPending ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                gap: "12px",
              }}
            >
              <span>Deleting</span>
              <Spinner size={14} />
            </div>
          ) : (
            "Delete"
          )}
        </Button>
      </Inline>
    </Grid>
  );
};

const SuccessMessage = ({ clusterName }: { clusterName: string }) => {
  return (
    <TitledFloatingMessage
      title="Cluster deleted"
      message={
        <>
          Cluster <strong>{clusterName}</strong> has been deleted successfully.
        </>
      }
    />
  );
};

const ErrorMessage = ({ clusterName }: { clusterName: string }) => {
  return (
    <TitledFloatingMessage
      title="Cluster not deleted"
      message={
        <>
          Cluster <strong>{clusterName}</strong> could not be deleted. Please
          try again later.
        </>
      }
    />
  );
};
