import { faSearch } from "@fortawesome/pro-regular-svg-icons";
import { Input, useBreakpoint } from "@intility/bifrost-react";
import type { PaginationState } from "@tanstack/react-table";
import { useState } from "react";
import { DataTable } from "~/components/table/DataTable";
import type { Cluster } from "~/types";
import styles from "./clusterTable.module.css";
import CallToAction from "./CallToAction";
import { ClusterStatusCounts } from "./ClusterStatusCounts";
import { useClusters } from "~/api/getClusters";
import { clusterColumns } from "./ClusterTableColumns";
import useRole from "~/hooks/useRole";
import { ColorSchemedImage } from "~/components/ColorSchemedImage";
import tropicalChillLight from "~/assets/tropical-chill-light.svg";
import tropicalChillDark from "~/assets/tropical-chill-dark.svg";
import { useNavigate } from "react-router-dom";

export const ClusterTable = () => {
  const clusters = useClusters({ refetchInterval: 10000 });
  const toMedium = useBreakpoint(null, "medium");
  const role = useRole();
  const navigate = useNavigate();

  const [clusterFilter, setClusterFilter] = useState("");

  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 6,
  });

  if (clusters.data?.length === 0 && !clusters.isPending) {
    return role.isAdmin ? <CallToAction /> : <NoClusters />;
  }

  const handleRowClick = (rowData: Cluster) => {
    const clusterName = rowData.name;

    navigate(`/clusters/${clusterName}`);
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h5 className={`bf-h5`}>All clusters</h5>
        <ClusterStatusCounts clusters={clusters.data ?? []} />
      </div>

      <div className={styles.searchInputContainer}>
        <Input
          className={styles.searchInput}
          placeholder="Look up cluster"
          label={undefined}
          icon={faSearch}
          clearable
          rightIcon
          value={clusterFilter}
          onChange={(e) => setClusterFilter(e.target.value)}
        />
      </div>

      <DataTable
        columns={clusterColumns}
        data={clusters.data}
        onPaginationChange={setPagination}
        state={{
          globalFilter: clusterFilter,
          pagination,
        }}
        initialState={{
          sorting: [{ id: "name", desc: false }],
        }}
        isLoading={clusters.isPending}
        noBorder
        limitExpandClick
        onRowClick={handleRowClick}
        ExpandedRowComponent={toMedium ? ExpandedClusterRow : undefined}
      />
    </div>
  );
};

const ExpandedClusterRow = ({ rowData }: { rowData: Cluster }) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        gap: "var(--bfs8)",
      }}
    >
      <span style={{ width: "50px", marginRight: "8px" }}>Console URL</span>
      <a
        className={`bf-link ${styles.clusterUrl}`}
        href={rowData.consoleUrl}
        target="_blank"
        rel="noreferrer"
      >
        {rowData.consoleUrl}
      </a>
    </div>
  );
};

const NoClusters = () => {
  return (
    <div className={styles.noClustersContainer}>
      <ColorSchemedImage
        srcLight={tropicalChillLight}
        srcDark={tropicalChillDark}
        alt=""
      />

      <h3 className="bf-h3">There are no clusters to see here yet</h3>
    </div>
  );
};
