import devPlatformLaptop from "~/assets/images/landing/dev-platform-laptop.png";
import { Tag } from "../Tag";
import styles from "./Hero.module.css";
import { faEnvelope } from "@fortawesome/pro-solid-svg-icons";
import { Button, Icon } from "@intility/bifrost-react";
import { Link } from "react-router-dom";
import { cn } from "~/utils/clsx";

export const Hero = () => {
  return (
    <section className={styles.section}>
      <img
        src={devPlatformLaptop}
        className={styles.heroBackground}
        alt="A laptop displaying Intility Developer Platform's home page"
      />

      <div className={styles.content}>
        <Tag>Intility Developer Platform</Tag>

        <h1 className={cn("bf-h1", styles.headline)}>
          Spend more time{" "}
          <span className={styles.headlineEmphasis}>developing</span> features
        </h1>

        <div className={styles.getStartedTextContent}>
          <p>
            Sign in to manage clusters, or get in touch to find the best
            solution for you and your company
          </p>
        </div>

        <div className={styles.heroButtonWrapper}>
          <Link to="/login">
            <Button
              pill
              variant="flat"
              className={cn(styles.button, styles.signInButton)}
            >
              Sign in
            </Button>
          </Link>

          <a href="mailto:dani.wold.kristiansen@intility.no">
            <Button
              pill
              variant="flat"
              className={cn(styles.button, styles.getInTouchButton)}
            >
              Get in touch
              <Icon icon={faEnvelope} marginLeft />
            </Button>
          </a>
        </div>
      </div>
    </section>
  );
};
