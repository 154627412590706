import { Tag } from "../Tag";
import threatDetection from "~/assets/images/landing/threat-detection.png";
import styles from "./InDevelopment.module.css";

export const InDevelopment = () => {
  return (
    <section className={styles.section}>
      <div className={styles.container}>
        <Tag>In Development</Tag>

        <h3 className="bf-h3">The platform is in continous development</h3>

        <img src={threatDetection} alt="" loading="lazy" />

        <p>
          Staying secure will be even easier with vulnerability scans for all
          your software and infrastructure configurations, as well as advanced
          threat detection with an AI driven response and automated handling of
          severe threats.
        </p>
      </div>
    </section>
  );
};
