import styles from "./createClusterPane.module.css";
import NameStep from "./NameStep";
import SpecificationsStep from "./SpecificationsStep";
import SummaryStep from "./SummaryStep";
import type { NewClusterForm, NewClusterStep } from "~/types";

const CreateClusterPane = ({
  activeStep,
  formData,
  setFormData,
}: {
  activeStep: NewClusterStep;
  formData: NewClusterForm;
  setFormData: (formData: NewClusterForm) => void;
}) => {
  return (
    <div className={styles.content}>
      {activeStep.name === "clusterName" ? (
        <NameStep formData={formData} setFormData={setFormData} />
      ) : activeStep.name === "specifications" ? (
        <SpecificationsStep formData={formData} setFormData={setFormData} />
      ) : activeStep.name === "summary" ? (
        <SummaryStep formData={formData} />
      ) : null}
    </div>
  );
};

export default CreateClusterPane;
