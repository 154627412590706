import { Skeleton, Table } from "@intility/bifrost-react";
import { cn } from "~/utils/clsx";
import type { Table as TTable } from "@tanstack/react-table";

export const DataTableSkeletonRows = <TData,>({
  table,
}: {
  table: TTable<TData>;
}) => {
  const pageSize = table.getState().pagination.pageSize;
  const skeletonRowCount = table.options.meta?.skeletonRows ?? pageSize;

  return (
    <Skeleton repeat={skeletonRowCount}>
      {table.getHeaderGroups().map((headerGroup) => (
        <Table.Row key={headerGroup.id}>
          {headerGroup.headers.map((header) => {
            const columnMeta = header.column.columnDef.meta;
            const cellClassname = columnMeta?.cellClassName;
            const sizeClassName = columnMeta?.fromSize;

            return (
              <Table.Cell
                key={header.id}
                className={cn(cellClassname, {
                  [`from-${sizeClassName}`]: sizeClassName,
                })}
              >
                <Skeleton.Text />
              </Table.Cell>
            );
          })}
        </Table.Row>
      ))}
    </Skeleton>
  );
};
