import { useQuery } from "@tanstack/react-query";
import { api } from "~/auth/fetch";
import { API } from "~/config/api";
import { QueryKey } from "~/constants";

const getProfilePicture = async () => {
  const blob = await api
    .get("v1.0/me/photos/120x120/$value", {
      prefixUrl: API.Graph.url,
    })
    .blob();

  return URL.createObjectURL(blob);
};

export const useProfilePicture = () => {
  return useQuery({
    queryKey: [QueryKey.ProfilePicture],
    queryFn: getProfilePicture,
    staleTime: Infinity,
    retry: false,
  });
};
