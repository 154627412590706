import { NodeSpecPreset } from "~/types";

export const getPresetSpecs = (preset: NodeSpecPreset) => {
  switch (preset) {
    case NodeSpecPreset.Minimal:
      return { CPU: 2, RAM: 8 };
    case NodeSpecPreset.Balanced:
      return { CPU: 4, RAM: 16 };
    case NodeSpecPreset.Performance:
      return { CPU: 6, RAM: 24 };
    default:
      return { CPU: null, RAM: null };
  }
};
