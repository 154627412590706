import { MsalProvider } from "@azure/msal-react";
import { FloatingMessage } from "@intility/bifrost-react";
import type { ReactNode } from "react";
import { Outlet, ScrollRestoration } from "react-router-dom";
import { instance } from "~/auth";
import { Authentication } from "~/auth/Authentication";
import Navigation from "~/components/Navigation";
import { ModalProvider } from "~/context/ModalContext";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

type ProviderProps = {
  children: ReactNode;
};

export const AppProvider = ({ children }: ProviderProps) => {
  return (
    <>
      <MsalProvider instance={instance}>{children}</MsalProvider>
      <ScrollRestoration />
    </>
  );
};

const queryClient = new QueryClient();

export const ProtectedApp = () => {
  return (
    <Authentication>
      <QueryClientProvider client={queryClient}>
        <FloatingMessage timeout={5000}>
          <ModalProvider>
            <Navigation>
              <Outlet />
            </Navigation>
          </ModalProvider>
        </FloatingMessage>

        {import.meta.env.DEV && <ReactQueryDevtools />}
      </QueryClientProvider>
    </Authentication>
  );
};

export const PublicApp = () => {
  return <Outlet />;
};
