import { wrapCreateBrowserRouter } from "@sentry/react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Outlet,
  Route,
} from "react-router-dom";
import { ErrorPage } from "./pages/error/ErrorPage";
import HelpCenterPage from "./pages/helpCenter/HelpCenterPage";
import Home from "./pages/home/Home";
import { Landing } from "./features/landing/routes/Landing";
import { SignInPage } from "./features/sign-in/SignInPage";
import { AppProvider, ProtectedApp, PublicApp } from "./providers/app";
import { AdminRoute } from "./routes/admin";
import { Clusters } from "./features/clusters/routes/Clusters";
import { NewCluster } from "./features/clusters/routes/NewCluster";
import { Cluster } from "./features/clusters/routes/Cluster";

/**
 * Sentry integration
 * @see https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
 */
const sentryCreateBrowserRouter = wrapCreateBrowserRouter(createBrowserRouter);

/**
 * The applications router, using react-router data APIs
 * @see https://reactrouter.com/en/main/routers/create-browser-router
 */
const router = sentryCreateBrowserRouter(
  createRoutesFromElements(
    <Route
      errorElement={<ErrorPage />}
      element={
        <AppProvider>
          <Outlet />
        </AppProvider>
      }
    >
      <Route path="*" element={<ErrorPage code={404} />} />

      <Route element={<PublicApp />}>
        <Route path="/" element={<Landing />} />
        <Route path="/login" element={<SignInPage />} />
      </Route>

      <Route element={<ProtectedApp />}>
        <Route path="/home" element={<Home />} />

        <Route path="/clusters">
          <Route index element={<Clusters />} />

          <Route path="new" element={<AdminRoute />}>
            <Route index element={<NewCluster />} />
          </Route>

          <Route path=":clusterId">
            <Route index element={<Cluster />} />
            {/* <Route path="edit" element={<EditCluster />} /> */}
          </Route>
        </Route>

        <Route path="/helpcenter" element={<HelpCenterPage />} />
      </Route>
    </Route>,
  ),
);

export { router };
