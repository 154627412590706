import styles from "./supportPage.module.css";
import {
  faComment,
  faPhone,
  faTicket,
} from "@fortawesome/pro-regular-svg-icons";
import SupportCard from "./components/SupportCard";

const SupportPage = () => {
  return (
    <div className={styles.content}>
      <div className={styles.introBanner}>
        <h5 className="bf-h5">Contact us! 🤓</h5>
        <span>
          Our support team would love to help you with whatever you need
        </span>
      </div>

      <div className={styles.contactOptions}>
        <SupportCard
          icon={faComment}
          title="Chat med oss"
          textContent="Få hjelp raskt og enkelt av en av våre teknikere."
          buttonHref="https://chat.intility.com"
          buttonText="Start chat"
        />

        <SupportCard
          icon={faPhone}
          title="Ring oss"
          textContent="Vil du heller prate? Ring oss, så skal vi hjelpe deg så godt vi kan!"
          buttonHref="tel:+4724103300"
          buttonText="+47 24 10 33 00"
        />

        <SupportCard
          icon={faTicket}
          title="Opprett sak"
          textContent="Opprett en mer detaljert problembeskrivelse og last opp bilder ved behov."
          buttonHref="https://my.intility.com/support/new"
          buttonText="Opprett ny sak"
        />
      </div>
    </div>
  );
};

export default SupportPage;
