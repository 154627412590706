import { ColorSchemedImage } from "~/components/ColorSchemedImage";
import bananaSlipDark from "~/assets/banana-slip-dark.svg";
import bananaSlipLight from "~/assets/banana-slip-light.svg";
import styles from "./UnavailableInfoError.module.css";
import { cn } from "~/utils/clsx";

export const UnavailableInfoError = ({ className }: { className?: string }) => {
  return (
    <div className={cn(styles.container, className)}>
      <ColorSchemedImage
        srcLight={bananaSlipLight}
        srcDark={bananaSlipDark}
        alt=""
      />

      <p className={styles.errorMessage}>
        Ooops, this information is not available right now
      </p>
    </div>
  );
};
