import cpmLogo from "~/assets/cpm-logo.svg";
import styles from "./SignInPage.module.css";

import { useIsAuthenticated } from "@azure/msal-react";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { SignInBackground } from "./components/SignInBackground";
import { SignInButton } from "./components/SignInButton";
import { SignInFooter } from "./components/SignInFooter";

export const SignInPage = () => {
  const navigate = useNavigate();
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/home");
    }
  }, [isAuthenticated, navigate]);

  return (
    <div className={styles.pageContainer}>
      <SignInBackground />

      <div className={styles.signInWrapper}>
        <Link to="/">
          <img src={cpmLogo} alt="" className={styles.cpmLogo} />
        </Link>

        <div className={styles.textSection}>
          <p className={styles.welcomeText}>Welcome to</p>
          <h5 className="bf-h5">Developer Platform</h5>
          <p className={styles.slogan}>
            Spend more time on development and less time on everything else
          </p>
        </div>

        <SignInButton />
        <SignInFooter />
      </div>
    </div>
  );
};
