import {
  faCircleDown,
  faCircleUp,
  faSpinner,
  type IconDefinition,
} from "@fortawesome/pro-solid-svg-icons";

export const ClusterStatusIcon = {
  ready: { icon: faCircleUp, color: "var(--bfc-base-c-success)" },
  notReady: { icon: faCircleDown, color: "var(--bfc-base-c-alert)" },
  deploying: { icon: faSpinner, color: "var(--bfc-base-c-2)" },
} as const satisfies Record<
  ClusterStatus,
  { icon: IconDefinition; color: string }
>;

export const QueryKey = {
  Clusters: "clusters",
  ProfilePicture: "profilePicture",
  Cluster: "cluster",
} as const;

export const ClusterStatus = {
  ready: "ready",
  notReady: "notReady",
  deploying: "deploying",
} as const;

export type ClusterStatus = (typeof ClusterStatus)[keyof typeof ClusterStatus];
