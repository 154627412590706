import signInBackgroundDark from "~/assets/backgrounds/signInBackgroundDark.svg";
import signInBackgroundLight from "~/assets/backgrounds/signInBackgroundLight.svg";
import styles from "./SignInBackground.module.css";

export const SignInBackground = () => {
  return (
    <picture className={styles.signInBackground}>
      <source
        srcSet={signInBackgroundDark}
        media="(prefers-color-scheme: dark)"
      />
      <source
        srcSet={signInBackgroundLight}
        media="(prefers-color-scheme: light)"
      />
      <img
        src={signInBackgroundDark}
        alt=""
        className={styles.signInBackground}
      />
    </picture>
  );
};
