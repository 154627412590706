import { useAccount } from "@azure/msal-react";

const useRole = () => {
  const account = useAccount();

  const isAdmin = account?.idTokenClaims?.roles?.includes(
    "Application.Admin.All",
  );
  const isReader = account?.idTokenClaims?.roles?.includes(
    "Application.Read.All",
  );

  return { isAdmin, isReader };
};

export default useRole;
