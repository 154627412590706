import bananaSlipDark from "~/assets/banana-slip-dark.svg";
import bananaSlipLight from "~/assets/banana-slip-light.svg";
import styles from "./ErrorIllustration.module.css";

export const ErrorIllustration = () => {
  return (
    <picture className={styles.errorIllustration}>
      <source srcSet={bananaSlipDark} media="(prefers-color-scheme: dark)" />
      <source srcSet={bananaSlipLight} media="(prefers-color-scheme: light)" />
      <img src={bananaSlipDark} alt="Person slipping on a banana peel" />
    </picture>
  );
};
