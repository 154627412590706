import {
  Badge,
  CheckboxCard,
  Icon,
  Input,
  Label,
} from "@intility/bifrost-react";
import Slider from "./Slider";
import styles from "./specificationsStep.module.css";
import { faBicycle, faCar, faRocket } from "@fortawesome/pro-regular-svg-icons";
import { type NewClusterForm, NodeSpecPreset } from "~/types";

const SpecificationsStep = ({
  formData,
  setFormData,
}: {
  formData: NewClusterForm;
  setFormData: (formData: NewClusterForm) => void;
}) => {
  return (
    <div className={styles.content}>
      <div>
        <h4 className="bf-h4">Specifications</h4>
        <span className="bf-label-description">
          Adjust the slider to determine the cluster node count, and select a
          performance preset to determine processing power (CPU) and memory
          (RAM). CPU is the computational capability in the cluster to ensure
          computational power and efficiency, while RAM refers to the volatile
          memory present in each node of the cluster to ensure sufficient
          performance.
        </span>
      </div>

      <div className={styles.inputSection}>
        <div>
          <Label>Node count</Label>
          <div className={styles.sliderSection}>
            <Slider
              value={formData.nodeCount}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  nodeCount: parseInt(e.target.value),
                })
              }
            />
            <Input
              type="number"
              label=""
              value={formData.nodeCount}
              onChange={(e) => {
                if (parseInt(e.target.value) > 8) {
                  e.target.value = "8";
                }
                if (parseInt(e.target.value) < 2) {
                  e.target.value = "2";
                }
                setFormData({
                  ...formData,
                  nodeCount: parseInt(e.target.value),
                });
              }}
              className={styles.nodeCount}
            />
          </div>
        </div>

        <div>
          <Label>Processing power and memory</Label>
          <div className={styles.presetSection}>
            <CheckboxCard
              label={
                <>
                  <Icon
                    icon={faBicycle}
                    marginRight
                    color="var(--bfc-base-c-attn)"
                  />{" "}
                  Minimal
                </>
              }
              checked={formData.preset === NodeSpecPreset.Minimal}
              onChange={() =>
                setFormData({ ...formData, preset: NodeSpecPreset.Minimal })
              }
              type="radio"
              className={styles.presetCard}
            >
              The smallest possible cluster
              <Badge pill state="attn" className={styles.presetCardBadge}>
                2 CPU | 8 GB RAM
              </Badge>
            </CheckboxCard>
            <CheckboxCard
              label={
                <>
                  <Icon
                    icon={faCar}
                    marginRight
                    color="var(--bfc-base-c-attn)"
                  />{" "}
                  Balanced
                </>
              }
              checked={formData.preset === NodeSpecPreset.Balanced}
              onChange={() =>
                setFormData({
                  ...formData,
                  preset: NodeSpecPreset.Balanced,
                })
              }
              type="radio"
              className={styles.presetCard}
            >
              A balanced cluster for most workloads
              <Badge pill state="attn" className={styles.presetCardBadge}>
                4 CPU | 16 GB RAM
              </Badge>
            </CheckboxCard>
            <CheckboxCard
              label={
                <>
                  <Icon
                    icon={faRocket}
                    marginRight
                    color="var(--bfc-base-c-attn)"
                  />{" "}
                  Performance
                </>
              }
              checked={formData.preset === NodeSpecPreset.Performance}
              onChange={() =>
                setFormData({ ...formData, preset: NodeSpecPreset.Performance })
              }
              type="radio"
              className={styles.presetCard}
            >
              For high performance workloads
              <Badge pill state="attn" className={styles.presetCardBadge}>
                6 CPU | 24 GB RAM
              </Badge>
            </CheckboxCard>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SpecificationsStep;
