import { faDown, faSpinner, faUp } from "@fortawesome/pro-solid-svg-icons";
import { AutoCol, Icon, Section, Spinner } from "@intility/bifrost-react";
import manCluelessDark from "~/assets/man-clueless-dark.svg";
import womanGesturing from "~/assets/woman-gesturing.svg";
import OverviewCard from "./OverviewCard";
import styles from "./overviewSection.module.css";
import { getClusterStatusCounts } from "~/utils/clusterStatus";
import type { ReactNode } from "react";
import { useClusters } from "~/api/getClusters";

interface OverviewSectionProps {
  className?: string;
}

const OverviewSection = ({ className }: OverviewSectionProps) => {
  const { data, isPending, isError } = useClusters();

  if (isPending) {
    return (
      <Layout className={className}>
        <div className={styles.loadingWrapper}>
          <Spinner className={styles.loadingSpinner} />
        </div>
      </Layout>
    );
  }

  if (isError) {
    return (
      <Layout className={className}>
        <div className={styles.errorWrapper}>
          <h4 className="bf-h4">No cluster info</h4>
          <p>This information is not available right now</p>

          <img
            src={manCluelessDark}
            height={150}
            alt="Confused person"
            className={`from-large ${styles.errorIllustration}`}
          />
        </div>
      </Layout>
    );
  }

  const clusterStatuses = getClusterStatusCounts(data);

  return (
    <Layout className={className}>
      <AutoCol>
        <OverviewCard
          dataValue={data.length}
          dataLabel="Total clusters"
          cardGraphics={
            <img src={womanGesturing} alt="Woman pointing at value" />
          }
          isHighlighted={true}
          centerGraphics={false}
        />
        <OverviewCard
          dataValue={clusterStatuses.deploying}
          dataLabel="Clusters deploying"
          cardGraphics={
            <div className={`${styles.cardIconBox} ${styles.dimmed}`}>
              <Icon className={`bf-h3 ${styles.dimmed}`} icon={faSpinner} />
            </div>
          }
        />
        <OverviewCard
          dataValue={clusterStatuses.ready}
          dataLabel="Clusters ready"
          cardGraphics={
            <div className={`${styles.cardIconBox} ${styles.success}`}>
              <Icon className={`bf-h3 ${styles.success}`} icon={faUp} />
            </div>
          }
        />
        <OverviewCard
          dataValue={clusterStatuses.notReady}
          dataLabel="Clusters not ready"
          cardGraphics={
            <div className={`${styles.cardIconBox} ${styles.alert}`}>
              <Icon className={`bf-h3 ${styles.alert}`} icon={faDown} />
            </div>
          }
        />
      </AutoCol>
    </Layout>
  );
};

const Layout = ({
  className,
  children,
}: { className?: string; children: ReactNode }) => {
  return (
    <Section className={`${className} ${styles.section}`}>
      <Section.Header>Overview</Section.Header>
      <Section.Content className={styles.sectionContent}>
        {children}
      </Section.Content>
    </Section>
  );
};

export default OverviewSection;
