import { Grid } from "@intility/bifrost-react";
import type { DisplayMode } from "../GuidesPage";
import GuideCard from "./GuideCard";
import GuideListItem from "./GuideListItem";

export type Guide = {
  imgSrc: string;
  imgAlt: string;
  title: string;
  ingress: string;
  href: string;
};

interface GuidesProps {
  displayMode: DisplayMode;
  guides: Guide[];
}

export const Guides = ({ displayMode, guides }: GuidesProps) => {
  return (
    <Grid gap={16} medium={displayMode === "tile" ? 2 : 1}>
      {guides.map((guide) => {
        return displayMode === "list" ? (
          <GuideListItem
            imgSrc={guide.imgSrc}
            imgAlt={guide.imgAlt}
            title={guide.title}
            ingress={guide.ingress}
            href={guide.href}
          />
        ) : (
          <GuideCard
            imgSrc={guide.imgSrc}
            imgAlt={guide.imgAlt}
            title={guide.title}
            ingress={guide.ingress}
            href={guide.href}
          />
        );
      })}
    </Grid>
  );
};
