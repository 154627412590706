import { faTrashCan, faWarning } from "@fortawesome/pro-solid-svg-icons";
import { Button, Icon } from "@intility/bifrost-react";
import { createColumnHelper } from "@tanstack/react-table";
import { useContext } from "react";
import { ModalContext } from "~/context/ModalContext";
import useRole from "~/hooks/useRole";
import type { Cluster } from "~/types";
import styles from "./clusterTable.module.css";
import { ClusterStatus, ClusterStatusIcon } from "~/constants";
import { DeleteModal } from "./DeleteModal";
import { Link } from "react-router-dom";
import { getClusterStatus } from "~/utils/clusterStatus";
import { cn } from "~/utils/clsx";
import { faExternalLink } from "@fortawesome/pro-regular-svg-icons";

const columnHelper = createColumnHelper<Cluster>();

export const clusterColumns = [
  columnHelper.accessor("name", {
    header: "Name",
    cell: function Cell(column) {
      const cluster = column.row.original;

      return (
        <Link
          className={cn("bf-title-link", styles.clusterName)}
          to={cluster.name}
          onClick={(e) => e.stopPropagation()}
        >
          {cluster.name}
        </Link>
      );
    },
  }),
  columnHelper.accessor("consoleUrl", {
    header: "Console URL",
    cell: function Cell(column) {
      const cluster = column.row.original;

      return (
        <a
          href={cluster.consoleUrl}
          target="_blank"
          rel="noreferrer"
          onClick={(e) => e.stopPropagation()}
        >
          <Button small state="neutral">
            Connect to cluster
            <Icon icon={faExternalLink} marginLeft />
          </Button>
        </a>
      );
    },
    meta: {
      fromSize: "medium",
    },
  }),
  columnHelper.accessor("status.ready.status", {
    header: "Status",
    cell: function Cell(column) {
      const cluster = column.row.original;
      const clusterStatus = getClusterStatus(cluster);

      return <StatusIcon clusterStatus={clusterStatus} />;
    },
  }),
  columnHelper.display({
    header: "",
    id: "delete",
    enableSorting: false,
    meta: {
      cellClassName: styles.iconCell,
    },
    cell: function Cell(column) {
      const { handleModal } = useContext(ModalContext);
      const role = useRole();

      if (!role.isAdmin) {
        return null;
      }

      const cluster = column.row.original;

      return (
        <Button
          variant="flat"
          state="neutral"
          pill
          aria-label="Delete cluster"
          onClick={(e) => {
            e.stopPropagation();

            handleModal({
              content: <DeleteModal cluster={cluster} />,
              header: (
                <h5 className="bf-h5">
                  <Icon
                    icon={faWarning}
                    className={styles.warningIcon}
                    marginRight
                    color="var(--bfc-base-c-alert)"
                  />
                  Delete cluster
                </h5>
              ),
            });
          }}
        >
          <Icon icon={faTrashCan} size="sm" />
        </Button>
      );
    },
  }),
];

interface StatusIconProps {
  clusterStatus: ClusterStatus;
}

const StatusIcon = ({ clusterStatus }: StatusIconProps) => {
  switch (clusterStatus) {
    case ClusterStatus.deploying: {
      return (
        <div>
          <Icon
            icon={ClusterStatusIcon.deploying.icon}
            color="var(--bfc-base-c-2)"
            marginRight
            spin
          />
          In deployment
        </div>
      );
    }

    case ClusterStatus.ready: {
      return (
        <div>
          <Icon
            icon={ClusterStatusIcon.ready.icon}
            color={ClusterStatusIcon.ready.color}
            marginRight
          />
          Ready
        </div>
      );
    }

    case ClusterStatus.notReady: {
      return (
        <div>
          <Icon
            icon={ClusterStatusIcon.notReady.icon}
            color={ClusterStatusIcon.notReady.color}
            marginRight
          />
          Not ready
        </div>
      );
    }

    default:
      return null;
  }
};
