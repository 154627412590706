import { Button } from "@intility/bifrost-react";
import intilityShorthandLogoDark from "~/assets/intility-shorthand-logo-dark.svg";
import intilityShorthandLogoLight from "~/assets/intility-shorthand-logo-light.svg";
import { instance } from "~/auth";
import styles from "./SignInButton.module.css";
import {
  InteractionRequiredAuthError,
  InteractionStatus,
} from "@azure/msal-browser";
import { loginRequest } from "~/auth/Authentication";
import { useNavigate } from "react-router-dom";
import { captureException } from "@sentry/react";
import { useMsal } from "@azure/msal-react";

export const SignInButton = () => {
  const navigate = useNavigate();
  const { inProgress } = useMsal();

  const isLoggingIn = inProgress !== InteractionStatus.None;

  const handleSignIn = async () => {
    if (isLoggingIn) {
      return;
    }

    await instance
      .ssoSilent({
        ...loginRequest,
        redirectUri: `${window.location.origin}/blank.html`,
      })
      .catch(async (error) => {
        if (error instanceof InteractionRequiredAuthError) {
          await instance.loginRedirect(loginRequest);
        } else {
          captureException(error);
        }
      });

    navigate("/home");
  };

  return (
    <Button
      variant="filled"
      state={isLoggingIn ? "inactive" : "neutral"}
      pill
      className={styles.signInButton}
      onClick={() => void handleSignIn()}
    >
      <picture>
        <source
          srcSet={intilityShorthandLogoLight}
          media="(prefers-color-scheme: light)"
        />
        <source
          srcSet={intilityShorthandLogoDark}
          media="(prefers-color-scheme: dark)"
        />
        <img src={intilityShorthandLogoDark} alt="" />
      </picture>
      Sign in with Intility (SSO)
      {isLoggingIn && "…"}
    </Button>
  );
};
