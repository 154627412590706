import styles from "./SignInFooter.module.css";
import intilityTextLogoDark from "~/assets/intility-text-logo-dark.svg";
import intilityTextLogoLight from "~/assets/intility-text-logo-light.svg";

export const SignInFooter = () => {
  return (
    <div className={styles.footer}>
      <span>an</span>
      <picture>
        <source
          srcSet={intilityTextLogoLight}
          media="(prefers-color-scheme: light)"
        />
        <source
          srcSet={intilityTextLogoDark}
          media="(prefers-color-scheme: dark)"
        />
        <img src={intilityTextLogoDark} alt="Intility" />
      </picture>
      <span>platform service</span>
    </div>
  );
};
