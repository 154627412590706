import { Icon, useBreakpoint } from "@intility/bifrost-react";
import styles from "./createClusterMenu.module.css";
import {
  faChartLineUp,
  faMemo,
  faSignature,
} from "@fortawesome/pro-regular-svg-icons";
import type { NewClusterStep } from "~/types";

const CreateClusterMenu = ({
  steps,
  activeStep,
  setActiveStep,
}: {
  steps: NewClusterStep[];
  activeStep: NewClusterStep;
  setActiveStep: (activeStep: NewClusterStep) => void;
}) => {
  const toLarge = useBreakpoint(null, "large");

  return (
    <div className={styles.content}>
      {toLarge === true ? (
        <h2 className={`bf-h2 ${styles.title}`}>Create new cluster</h2>
      ) : (
        <h5 className={`bf-h5 ${styles.title}`}>Create new cluster</h5>
      )}

      <div className={styles.menuItemContainer}>
        {steps.map((step, index) => (
          <button
            key={index}
            className={
              step.name === activeStep.name
                ? `${styles.menuItem} ${styles.active}`
                : styles.menuItem
            }
            onClick={() => setActiveStep(step)}
          >
            <Icon
              icon={
                step.name === "clusterName"
                  ? faSignature
                  : step.name === "specifications"
                    ? faChartLineUp
                    : step.name === "summary"
                      ? faMemo
                      : faSignature
              }
            />
            <span>
              {toLarge && step.name == "clusterName" ? "Name" : step.title}
            </span>
          </button>
        ))}
      </div>
    </div>
  );
};

export default CreateClusterMenu;
