import { Breakpoint } from "@intility/bifrost-react";
import styles from "./pageBanner.module.css";

type PageBannerProps = {
  title: string;
  subTitle: string;
  content?: React.ReactNode;
  hideGradient?: boolean;
  className?: string;
};

const PageBanner: React.FC<PageBannerProps> = ({
  title,
  subTitle,
  content,
  hideGradient,
  className,
}) => {
  return (
    <div
      className={
        hideGradient
          ? `${styles.banner} ${className}`
          : `${styles.banner} ${styles.gradient} ${className}`
      }
    >
      <div className={styles.titleSection}>
        <h1 className="bf-h1">{title}</h1>
        <Breakpoint from="small">
          <div className="bf-label-description">{subTitle}</div>
        </Breakpoint>
      </div>

      {content}
    </div>
  );
};

export default PageBanner;
