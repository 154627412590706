import styles from "./guideCard.module.css";

const GuideCard = ({
  imgSrc,
  imgAlt,
  title,
  ingress,
  href,
}: {
  imgSrc: string;
  imgAlt: string;
  title: string;
  ingress: string;
  href: string;
}) => {
  return (
    <a href={href} target="_blank" rel="noreferrer">
      <div className={styles.card}>
        <img
          className={styles.cardImage}
          src={imgSrc}
          alt={imgAlt}
          width={197}
          height={166}
        />
        <div className={styles.textSection}>
          <h5 className="bf-h5">{title}</h5>
          <span>{ingress}</span>
        </div>
      </div>
    </a>
  );
};

export default GuideCard;
