import styles from "./helpCenterPage.module.css";
import PageBanner from "~/components/PageBanner";
import GuidesPage from "./pages/GuidesPage/GuidesPage";
import SupportPage from "./pages/SupportPage/SupportPage";
import { useNavigate, useSearchParams } from "react-router-dom";
import wayfinderDark from "~/assets/wayfinder-dark.svg";
import wayfinderMobileDark from "~/assets/wayfinder-mobile-dark.svg";
import { Button, useBreakpoint } from "@intility/bifrost-react";
import { ColorSchemedImage } from "~/components/ColorSchemedImage";
import wayfinderLight from "~/assets/wayfinder-light.svg";
import wayfinderMobileLight from "~/assets/wayfinder-mobile-light.svg";

const HelpCenterPage = () => {
  const navigate = useNavigate();
  const [URLSearch] = useSearchParams();
  const selectedTab = URLSearch.getAll("tab");
  const isMobile = useBreakpoint(null, "small");

  return (
    <div className={styles.content}>
      <PageBanner
        title="Help center"
        subTitle="Guides and contact information"
        content={
          <ColorSchemedImage
            className={isMobile ? `${styles.pageBannerMobile}` : ""}
            srcLight={isMobile ? wayfinderMobileLight : wayfinderLight}
            srcDark={isMobile ? wayfinderMobileDark : wayfinderDark}
            alt="illustration of woman looking around"
          />
        }
        hideGradient={isMobile}
      />

      <Button.Group className={styles.tabButtonGroup}>
        <Button
          className={styles.tabButton}
          active={selectedTab.includes("guides")}
          onClick={() => {
            navigate("?tab=guides");
          }}
        >
          Guides
        </Button>
        <Button
          className={styles.tabButton}
          active={selectedTab.includes("support")}
          onClick={() => {
            navigate("?tab=support");
          }}
        >
          Support
        </Button>
      </Button.Group>

      {selectedTab.includes("guides") ? <GuidesPage /> : <SupportPage />}
    </div>
  );
};

export default HelpCenterPage;
