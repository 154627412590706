import { Breadcrumbs } from "@intility/bifrost-react";
import { Link, useLocation } from "react-router-dom";
import type { Cluster } from "~/types";
import styles from "./ClusterBreadcrumbs.module.css";

interface ClusterBreadcrumbsProps {
  cluster: Cluster | undefined;
}

export const ClusterBreadcrumbs = ({ cluster }: ClusterBreadcrumbsProps) => {
  const { pathname } = useLocation();

  const clusterName = cluster?.name;

  const breadcrumbs = [
    { route: `/clusters/${clusterName}`, label: clusterName },
    { route: `/clusters/${clusterName}/edit`, label: "Edit cluster spec" },
  ];

  const currentRouteIndex = breadcrumbs.findIndex(
    ({ route }) => route === pathname,
  );

  const breadcrumbsToDisplay = breadcrumbs.slice(0, currentRouteIndex + 1);
  const lastIndex = breadcrumbsToDisplay.length - 1;

  return (
    <div className={styles.container}>
      <Breadcrumbs>
        <Breadcrumbs.Item>
          <Link to="/clusters">Clusters</Link>
        </Breadcrumbs.Item>

        {breadcrumbsToDisplay.map(({ route, label }, index) => (
          <Breadcrumbs.Item key={route}>
            {index === lastIndex ? (
              <span>{label}</span>
            ) : (
              <Link to={route}>{label}</Link>
            )}
          </Breadcrumbs.Item>
        ))}
      </Breadcrumbs>
    </div>
  );
};
