import styles from "./callToAction.module.css";
import pekeDame from "~/assets/peke-dame.svg";

const CallToAction = () => {
  return (
    <div className={styles.illustration}>
      <span className="bf-h3">
        Click the button to <br /> create your first cluster
      </span>
      <img width={177} src={pekeDame} alt="Call to action illustration" />
    </div>
  );
};

export default CallToAction;
