import { faCopy, faExternalLink } from "@fortawesome/pro-solid-svg-icons";
import {
  Box,
  Button,
  Icon,
  Tooltip,
  useFloatingMessage,
} from "@intility/bifrost-react";
import type { Cluster } from "~/types";
import styles from "./ClusterAdministrationCards.module.css";
import { useState } from "react";
import { getOcLoginCommand } from "../../utils/getLoginCommand";

interface ClusterAdministrationCardsProps {
  cluster: Cluster;
}

export const ClusterAdministrationCards = ({
  cluster,
}: ClusterAdministrationCardsProps) => {
  const ocLoginCommand = getOcLoginCommand(cluster);

  return (
    <>
      <Box
        padding
        radius
        shadow
        background="base-3"
        className={styles.cardContainer}
      >
        <div>
          <div className={styles.cardTitle}>Console URL</div>

          <p className={styles.cardDescription}>
            To connect to and access your cluster in your browser, click the
            button and login with SSO.
          </p>
        </div>

        <a
          href={cluster.consoleUrl}
          target="_blank"
          rel="noreferrer"
          className={styles.clusterConnectAnchor}
        >
          <Button state="neutral">
            Connect to cluster
            <Icon icon={faExternalLink} marginLeft />
          </Button>
        </a>
      </Box>

      <Box
        padding
        radius
        shadow
        background="base-3"
        className={styles.cardContainer}
      >
        <div>
          <div className={styles.cardTitle}>oc command</div>

          <p className={styles.cardDescription}>
            To connect to and access your cluster using the OpenShift CLI, copy
            the oc command.
          </p>
        </div>

        <CopyButton buttonText={ocLoginCommand} textToCopy={ocLoginCommand} />
      </Box>

      <Box
        padding
        radius
        shadow
        background="base-3"
        className={styles.cardContainer}
      >
        <div>
          <div className={styles.cardTitle}>indev command</div>

          <p className={styles.cardDescription}>
            To administrate your clusters using our CLI, copy the indev command.
            indev has to be installed.
          </p>
        </div>

        <CopyButton buttonText="indev login" textToCopy="indev login" />
      </Box>
    </>
  );
};

const CopyButton = ({
  buttonText,
  textToCopy,
}: { buttonText: string; textToCopy: string }) => {
  const { showFloatingMessage } = useFloatingMessage();

  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  const handleCopy = async () => {
    await navigator.clipboard.writeText(textToCopy).catch((e) => {
      showFloatingMessage("Failed to copy text", { state: "warning" });

      throw e;
    });

    setIsTooltipVisible(true);

    setTimeout(() => {
      setIsTooltipVisible(false);
    }, 1000);
  };

  return (
    <Button
      state="neutral"
      className={styles.copyButton}
      onClick={() => void handleCopy()}
    >
      <span>{buttonText}</span>

      <Tooltip content="Copied!" placement="top" visible={isTooltipVisible}>
        <Icon icon={faCopy} marginLeft />
      </Tooltip>
    </Button>
  );
};
