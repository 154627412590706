import { useFloatingMessage } from "@intility/bifrost-react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  type NewClusterForm,
  type NewClusterStep,
  NodeSpecPreset,
} from "~/types";
import createRandomString from "~/utils/createRandomString";
import { isValidClusterName } from "~/utils/validations";
import { useCreateCluster } from "../api/createCluster";
import BreadcrumbsHeader from "../components/newCluster/BreadcrumbsHeader";
import CreateClusterFooter from "../components/newCluster/CreateClusterFooter";
import CreateClusterMenu from "../components/newCluster/CreateClusterMenu";
import CreateClusterPane from "../components/newCluster/CreateClusterPane";
import styles from "./newCluster.module.css";
import { TitledFloatingMessage } from "~/components/TitledFloatingMessage";

const steps: NewClusterStep[] = [
  {
    id: 0,
    name: "clusterName",
    title: "Cluster name",
  },
  {
    id: 1,
    name: "specifications",
    title: "Specifications",
  },
  {
    id: 2,
    name: "summary",
    title: "Summary",
  },
];

export const NewCluster = () => {
  const navigate = useNavigate();
  const createCluster = useCreateCluster();

  const [activeStep, setActiveStep] = useState<NewClusterStep>(steps[0]);
  const [formData, setFormData] = useState<NewClusterForm>({
    clusterName: "",
    clusterNameSuffix: createRandomString(6),
    nodeCount: 2,
    preset: NodeSpecPreset.Minimal,
  });

  const resetForm = () => {
    setFormData({
      clusterName: "",
      clusterNameSuffix: createRandomString(6),
      nodeCount: 2,
      preset: NodeSpecPreset.Minimal,
    });
  };

  const { showFloatingMessage } = useFloatingMessage();

  const handleCreate = () => {
    const clusterName = formData.clusterName + "-" + formData.clusterNameSuffix;

    createCluster.mutate(
      {
        name: clusterName,
        nodePools: [{ preset: formData.preset, replicas: formData.nodeCount }],
      },
      {
        onSuccess: () => {
          showFloatingMessage(<SuccessMessage clusterName={clusterName} />, {
            state: "success",
            noIcon: true,
          });
        },
        onError: () => {
          showFloatingMessage(<ErrorMessage clusterName={clusterName} />, {
            state: "warning",
            noIcon: true,
          });
        },
        onSettled: () => {
          navigate("/clusters");
        },
      },
    );
  };

  return (
    <div className={styles.content}>
      <BreadcrumbsHeader />

      <div className={styles.wizard}>
        <div className={styles.menu}>
          <CreateClusterMenu
            steps={steps}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
          />
        </div>

        <div style={{ display: "flex", flexDirection: "column" }}>
          <CreateClusterPane
            activeStep={activeStep}
            formData={formData}
            setFormData={setFormData}
          />
          <CreateClusterFooter
            steps={steps}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            cancelButtonOnClick={() => {
              navigate("/clusters");
              resetForm();
            }}
            createButtonOnClick={handleCreate}
            createButtonDisabled={!isValidClusterName(formData.clusterName)}
            createButtonFetching={createCluster.isPending}
          />
        </div>
      </div>
    </div>
  );
};

const SuccessMessage = ({ clusterName }: { clusterName: string }) => {
  return (
    <TitledFloatingMessage
      title="Cluster created"
      message={
        <>
          Cluster <strong>{clusterName}</strong> has been created successfully.
        </>
      }
    />
  );
};

const ErrorMessage = ({ clusterName }: { clusterName: string }) => {
  return (
    <TitledFloatingMessage
      title="Could not create cluster"
      message={
        <>
          Cluster <strong>{clusterName}</strong> could not be created. Please
          try again later.
        </>
      }
    />
  );
};
