import ky from "ky";

import { API } from "~/config/api";
import { instance } from "./instance";

export const api = ky.extend({
  retry: 0,
  timeout: 30000,
  hooks: {
    beforeRequest: [
      async (request) => {
        const apiConfig = API;

        const requestedApi = Object.values(apiConfig).find((api) =>
          request.url.startsWith(api.url),
        );

        const scopes = requestedApi ? [requestedApi.scope] : [];

        if (scopes.length) {
          const token = await instance.acquireTokenSilent({
            scopes,
          });

          request.headers.set("Authorization", "Bearer " + token.accessToken);
        }

        return request;
      },
    ],
  },
});
