import { cn } from "~/utils/clsx";
import styles from "./Landing.module.css";
import { CustomerCarousel } from "../components/sections/CustomerCarousel";
import { NoInfrastructureWorries } from "../components/sections/NoInfrastructureWorries";
import { InDevelopment } from "../components/sections/InDevelopment";
import { EngineeringBlog } from "../components/sections/EngineeringBlog";
import { DeveloperProductivity } from "../components/sections/DeveloperProductivity";
import { Hero } from "../components/sections/Hero";

export const Landing = () => {
  return (
    <div
      className={cn("bf-darkmode bfc-base-bg bf-satoshi", styles.pageContainer)}
    >
      <Hero />
      <DeveloperProductivity />
      <NoInfrastructureWorries />
      <CustomerCarousel />
      <EngineeringBlog />
      <InDevelopment />
    </div>
  );
};
