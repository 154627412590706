import type { ReactNode } from "react";
import styles from "./TitledFloatingMessage.module.css";

export const TitledFloatingMessage = ({
  title,
  message,
}: {
  title: string;
  message: ReactNode;
}) => {
  return (
    <div className={styles.floatingMessage}>
      <div className={styles.floatingMessageTitle}>{title}</div>
      <div>{message}</div>
    </div>
  );
};
