import { Icon } from "@intility/bifrost-react";
import styles from "./createButton.module.css";
import { faCirclePlus } from "@fortawesome/pro-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import useRole from "~/hooks/useRole";

const CreateButton = () => {
  const navigate = useNavigate();
  const role = useRole();

  if (!role.isAdmin) {
    return null;
  }

  return (
    <button
      className={styles.createButton}
      onClick={() => navigate("/clusters/new")}
    >
      <Icon icon={faCirclePlus} fontSize={30} />
      <h5 className={`bf-h5 ${styles.buttonText}`}>Create new cluster</h5>
    </button>
  );
};

export default CreateButton;
