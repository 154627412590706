import { Button, Icon, Inline, Section } from "@intility/bifrost-react";
import styles from "./supportCard.module.css";
import type { IconDefinition } from "@fortawesome/pro-regular-svg-icons";

const SupportCard = ({
  icon,
  title,
  textContent,
  buttonHref,
  buttonText,
}: {
  icon: IconDefinition;
  title: string;
  textContent: string;
  buttonHref: string;
  buttonText: string;
}) => {
  return (
    <Section className={styles.card}>
      <Section.Header>
        <Inline align="center">
          <Icon icon={icon} />
          <Inline.Stretch>{title}</Inline.Stretch>
        </Inline>
      </Section.Header>

      <Section.Content className={styles.content}>
        <div className={styles.cardTextContent}>{textContent}</div>

        <a
          href={buttonHref}
          target="_blank"
          rel="noreferrer"
          className={styles.buttonAnchor}
        >
          <Button state="neutral" className={styles.cardButton}>
            {buttonText}
          </Button>
        </a>
      </Section.Content>
    </Section>
  );
};

export default SupportCard;
