import cpmHome from "~/assets/cpm-home.svg";
import GuidesSection from "./components/GuidesSection";
import OverviewSection from "./components/OverviewSection";
import RoleSection from "./components/RoleSection";
import styles from "./home.module.css";

export default function Home() {
  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <img
          className={styles.logo}
          src={cpmHome}
          alt="developer platform logo"
        />
        <div>
          <h1 className="bf-h1">Welcome to the Developer Platform</h1>
          <span>
            Let us spend more time on development and less time on everything
            else
          </span>
        </div>
      </div>

      <div className={styles.sections}>
        <OverviewSection className={styles.overviewSection} />
        <GuidesSection className={styles.guidesSection} />
        <RoleSection className={styles.roleSection} />
      </div>
    </div>
  );
}
