import { Button } from "@intility/bifrost-react";
import styles from "./ErrorPage.module.css";
import { ErrorIllustration } from "./ErrorIllustration";
import { Link } from "react-router-dom";

const DefaultError = () => {
  return (
    <>
      <h1 className="bf-h1">Ooops, an error has occurred</h1>
      <p className={styles.errorDescription}>
        Our developers have been notified
      </p>
    </>
  );
};

const Forbidden = () => {
  return (
    <>
      <h1 className="bf-h1">Oh no, you do not have access to this page</h1>
      <p className={styles.errorDescription}>
        It appears that you do not have the necessary permissions to view this
        page. Please contact an administrator if you believe this is an error or
        return to the homepage.
      </p>
    </>
  );
};

const NotFound = () => {
  return (
    <>
      <h1 className="bf-h1">
        Ooops, we could not find the page you are looking for
      </h1>
      <p className={styles.errorDescription}>
        The page you are trying to reach does not exist or has been moved.
        Please check the URL or go back to the homepage.
      </p>
    </>
  );
};

const ServerError = () => {
  return (
    <>
      <h1 className="bf-h1">Ooops, something went wrong</h1>
      <p className={styles.errorDescription}>We could not reach our servers</p>
    </>
  );
};

interface ErrorPageProps {
  code?: number;
}

export const ErrorPage = ({ code }: ErrorPageProps) => {
  return (
    <div className={styles.pageContainer}>
      <div className={styles.pageContent}>
        <ErrorIllustration />

        {code && (
          <h5 className={`bf-h5 ${styles.errorCodeTitle}`}>
            Error code: {code}
          </h5>
        )}

        {code === 403 ? (
          <Forbidden />
        ) : code === 404 ? (
          <NotFound />
        ) : code === 500 ? (
          <ServerError />
        ) : (
          <DefaultError />
        )}

        <div className={styles.errorButtonsWrapper}>
          <Link to="/home">
            <Button
              variant="filled"
              state="neutral"
              className={styles.errorButton}
            >
              To homepage
            </Button>
          </Link>

          <Link to="helpcenter?tab=support">
            <Button
              variant="outline"
              state="neutral"
              className={styles.errorButton}
            >
              Support
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
};
