import { Outlet } from "react-router-dom";
import useRole from "~/hooks/useRole";
import { ErrorPage } from "~/pages/error/ErrorPage";

export const AdminRoute = () => {
  const role = useRole();

  if (!role.isAdmin) {
    return <ErrorPage code={403} />;
  }

  return <Outlet />;
};
