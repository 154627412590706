import React from "react";
import styles from "./slider.module.css";

type SliderProps = {
  value: number;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const Slider: React.FC<SliderProps> = ({ value, onChange }) => {
  return (
    <input
      type="range"
      value={value}
      min={2}
      max={8}
      className={styles.something}
      onChange={onChange}
    />
  );
};

export default Slider;
