import {
  faBookSparkles,
  faShieldCheck,
  faWarning,
} from "@fortawesome/pro-regular-svg-icons";
import { Icon } from "@intility/bifrost-react";
import infrastructureManagement from "~/assets/images/landing/infrastructure-management.png";
import styles from "./NoInfrastructureWorries.module.css";
import { cn } from "~/utils/clsx";

export const NoInfrastructureWorries = () => {
  return (
    <section className={styles.section}>
      <div className={styles.container}>
        <img
          src={infrastructureManagement}
          className={styles.backgroundImage}
          style={{ position: "relative" }}
          alt=""
        />

        <div className={cn(styles.textContent)}>
          <h3 className="bf-h3">
            No{" "}
            <span className={styles.infrastructureEmphasis}>
              infrastructure
            </span>{" "}
            worries
          </h3>

          <p>
            Management of underlying infrastructure and close collaboration with
            certified Kubernetes experts
          </p>

          <div className={styles.list}>
            <span>
              <Icon
                icon={faWarning}
                color="var(--bfc-base-c-attn)"
                marginRight
              />
              Incident management
            </span>

            <span>
              <Icon
                icon={faShieldCheck}
                color="var(--bfc-base-c-attn)"
                marginRight
              />
              Vulnerability mitigation
            </span>

            <span>
              <Icon
                icon={faBookSparkles}
                color="var(--bfc-base-c-attn)"
                marginRight
              />
              Automatic documentation
            </span>
          </div>
        </div>
      </div>
    </section>
  );
};
