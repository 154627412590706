import { cn } from "~/utils/clsx";
import styles from "./overviewCard.module.css";

const OverviewCard = ({
  dataValue,
  dataLabel,
  cardGraphics,
  centerGraphics = true,
  isHighlighted = false,
}: {
  dataValue: number;
  dataLabel: string;
  cardGraphics: React.ReactNode;
  centerGraphics?: boolean;
  isHighlighted?: boolean;
}) => {
  return (
    <div className={cn(styles.overviewCard)}>
      <div className={styles.cardData}>
        <h2
          className={cn("bf-h2", styles.dataValue, {
            [styles.valueHighlighted]: isHighlighted,
          })}
        >
          {dataValue}
        </h2>
        <span
          className={cn(styles.dataLabel, {
            [styles.labelHighlighted]: isHighlighted,
          })}
        >
          {dataLabel}
        </span>
      </div>

      <div
        className={cn(styles.cardGraphics, {
          [styles.centered]: centerGraphics,
        })}
      >
        {cardGraphics}
      </div>
    </div>
  );
};

export default OverviewCard;
