import {
  faCar,
  faCircleNodes,
  faTrash,
} from "@fortawesome/pro-regular-svg-icons";
import { Box, Button, Grid, Icon } from "@intility/bifrost-react";
import { useContext } from "react";
import { ClusterStatus, ClusterStatusIcon } from "~/constants";
import { ModalContext } from "~/context/ModalContext";
import type { Cluster } from "~/types";
import { cn } from "~/utils/clsx";
import { getClusterStatus } from "~/utils/clusterStatus";
import { DeleteModal } from "../clusterList/DeleteModal";
import styles from "./ClusterInfoCard.module.css";

interface ClusterInfoCardProps {
  cluster: Cluster;
}

export const ClusterInfoCard = ({ cluster }: ClusterInfoCardProps) => {
  const { handleModal } = useContext(ModalContext);

  const clusterName = cluster.name;
  const clusterStatus = getClusterStatus(cluster);

  return (
    <Box
      padding
      shadow
      radius
      background="base-3"
      className={styles.cardContainer}
    >
      <div className={styles.infoHeader}>
        <div>
          <div>
            <Icon
              icon={ClusterStatusIcon[clusterStatus].icon}
              color={ClusterStatusIcon[clusterStatus].color}
              spin={clusterStatus === ClusterStatus.deploying}
              marginRight
            />

            <span>
              {clusterStatus === ClusterStatus.deploying && "In deployment"}
              {clusterStatus === ClusterStatus.ready && "Ready"}
              {clusterStatus === ClusterStatus.notReady && "Not ready"}
            </span>
          </div>

          <h2 className={cn("bf-h2", styles.clusterName)}>{clusterName}</h2>
        </div>

        <div className={cn("from-small", styles.headerButtonContainer)}>
          <Button
            state="alert"
            variant="flat"
            onClick={() =>
              handleModal({
                header: <>Delete cluster</>,
                content: <DeleteModal cluster={cluster} />,
              })
            }
          >
            <Icon icon={faTrash} />
            <span className={cn("from-small", styles.deleteClusterLabel)}>
              Delete cluster
            </span>
          </Button>
        </div>
      </div>

      <Grid medium={2} large={3} className={styles.specificationsContainer}>
        <ClusterSpecificationCards cluster={cluster} />
      </Grid>

      <Button
        className="to-small"
        state="alert"
        variant="flat"
        style={{ marginTop: "var(--bfs16)" }}
        onClick={() =>
          handleModal({
            header: <>Delete cluster</>,
            content: <DeleteModal cluster={cluster} />,
          })
        }
      >
        <Icon icon={faTrash} marginRight />
        Delete cluster
      </Button>
    </Box>
  );
};

const ClusterSpecificationCards = ({ cluster }: { cluster: Cluster }) => {
  const nodePool = cluster.nodePools.at(0);
  const cores = nodePool?.compute?.cores;
  const memory = nodePool?.compute?.memory;
  const nodeCount = nodePool?.replicas;

  const memoryAmount = memory?.split("Gi").at(0);
  const memoryDisplay = memoryAmount ? `${memoryAmount} GB` : undefined;

  return (
    <>
      <Grid.Span large={2}>
        <Box padding radius background="base-2" className={styles.computeCard}>
          <div className={styles.iconBackground}>
            <Icon icon={faCar} fixedWidth color="var(--bfc-base-c-attn)" />
          </div>

          <div>
            <div className={styles.computeTitle}>
              <span>{cores} CPU</span>
              <span className={styles.computeDivider} />
              <span>{memoryDisplay} RAM</span>
            </div>

            <p className={styles.computeDescription}>
              Processing power and memory
            </p>
          </div>
        </Box>
      </Grid.Span>

      <Box padding radius background="base-2" className={styles.nodeCountCard}>
        <div className={styles.iconBackground}>
          <Icon
            icon={faCircleNodes}
            fixedWidth
            color="var(--bfc-base-c-attn)"
          />
        </div>

        <div>
          <div className={styles.nodeCountTitle}>{nodeCount} nodes</div>
          <div className={styles.nodeCountDescription}>Node count</div>
        </div>
      </Box>
    </>
  );
};
