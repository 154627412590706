interface ColorSchemedImageProps {
  srcLight: string;
  srcDark: string;
  alt: string;
  className?: string;
}

export const ColorSchemedImage = ({
  srcLight,
  srcDark,
  alt,
  className,
}: ColorSchemedImageProps) => {
  return (
    <picture>
      <source
        className={className}
        srcSet={srcLight}
        media="(prefers-color-scheme: light)"
      />

      <source
        className={className}
        srcSet={srcDark}
        media="(prefers-color-scheme: dark)"
      />

      <img className={className} src={srcDark} alt={alt} />
    </picture>
  );
};
