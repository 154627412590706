import { faArrowRight } from "@fortawesome/pro-solid-svg-icons";
import { Button, Icon, Card, Grid } from "@intility/bifrost-react";
import { cn } from "~/utils/clsx";
import { Tag } from "../Tag";
import styles from "./EngineeringBlog.module.css";
import keda from "~/assets/images/landing/keda.jpg";
import highAvailability from "~/assets/images/landing/high-availability.jpg";
import architectingKubernetesPlatform from "~/assets/images/landing/architecting-kubernetes-platform.png";

export const EngineeringBlog = () => {
  return (
    <section className={styles.section}>
      <img
        src={architectingKubernetesPlatform}
        alt=""
        className={styles.backgroundImage}
        loading="lazy"
      />

      <div className={styles.container}>
        <div className={styles.headerContent}>
          <Tag>New Blog Entry</Tag>

          <h2 className={cn("bf-h2", styles.blogTitle)}>
            Architecting a Scalable Kubernetes Platform
          </h2>

          <p className={styles.blogDescription}>
            Take a peek inside the machinery with our Engineering blog. Discover
            how to build a cutting-edge, scalable Kubernetes platform that
            ensures efficient infrastructure, robust networking, and seamless
            cloud integration for your business.
          </p>

          <a
            href="https://engineering.intility.com/article/architecting-a-scalable-kubernetes-platform"
            target="_blank"
            rel="noreferrer"
          >
            <Button
              pill
              variant="flat"
              className={cn(styles.button, styles.blogPeekButton)}
            >
              Take a peek
              <Icon icon={faArrowRight} marginLeft />
            </Button>
          </a>
        </div>

        <Grid medium={2} className={styles.cardsContainer}>
          {cardBlogArticles.map((article) => (
            <Card key={article.title} className={styles.card}>
              <Card.Image url={article.image} className={styles.cardImage} />

              <Card.Title className={styles.cardTitle}>
                {article.title}
              </Card.Title>

              <Card.Content className={styles.cardContent}>
                <div>{article.content}</div>

                <div className={styles.cardButtonWrapper}>
                  <a
                    href={article.href}
                    target="_blank"
                    rel="noreferrer"
                    title="Visit blog post"
                  >
                    <Button noPadding variant="flat" state="neutral">
                      <Icon
                        icon={faArrowRight}
                        size="xl"
                        color="var(--bfc-base-c-attn)"
                      />

                      <span hidden>Visit blog post</span>
                    </Button>
                  </a>
                </div>
              </Card.Content>
            </Card>
          ))}
        </Grid>
      </div>
    </section>
  );
};

const cardBlogArticles = [
  {
    title: "Scaling Kubernetes apps with KEDA",
    image: keda,
    content:
      "Some workloads experience high variations in load and usage. Take a closer look at how you can autoscale your Kubernetes apps by using KEDA.",
    href: "https://engineering.intility.com/article/scaling-kubernetes-apps-with-keda",
  },
  {
    title: "Guide to High Availability in Kubernetes",
    image: highAvailability,
    content:
      "A complete guide to running High Availability (HA) applications in Kubernetes.",
    href: "https://engineering.intility.com/article/guide-to-high-availability-in-kubernetes",
  },
];
