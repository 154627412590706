import { Box, Grid, Spinner } from "@intility/bifrost-react";
import type { PropsWithChildren } from "react";
import { useParams } from "react-router-dom";
import { useCluster } from "../api/getCluster";
import { ClusterBreadcrumbs } from "../components/clusterDetails/ClusterBreadcrumbs";
import { ClusterAdministrationCards } from "../components/clusterDetails/ClusterAdministrationCards";
import styles from "./Cluster.module.css";
import { ClusterInfoCard } from "../components/clusterDetails/ClusterInfoCard";
import type { Cluster as TCluster } from "~/types";
import { GuideCard } from "../components/clusterDetails/GuideCard";
import { UnavailableInfoError } from "~/components/UnavailableInfoError";

export const Cluster = () => {
  const { clusterId } = useParams();

  const cluster = useCluster(clusterId ?? "");

  if (cluster.isPending) {
    return (
      <Layout cluster={cluster.data}>
        <div className={styles.spinnerWrapper}>
          <Spinner className={styles.loadingSpinner} size={42} />
        </div>
      </Layout>
    );
  }

  if (cluster.isError) {
    return (
      <Layout cluster={cluster.data}>
        <UnavailableInfoError />
      </Layout>
    );
  }

  return (
    <Layout cluster={cluster.data}>
      <ClusterInfoCard cluster={cluster.data} />

      <Grid small={3}>
        <ClusterAdministrationCards cluster={cluster.data} />
      </Grid>

      <GuideCard />
    </Layout>
  );
};

const Layout = ({
  children,
  cluster,
}: PropsWithChildren<{ cluster: TCluster | undefined }>) => {
  return (
    <div className={styles.content}>
      <Box shadow radius background="base-3" className={styles.box}>
        <ClusterBreadcrumbs cluster={cluster} />
      </Box>

      {children}
    </div>
  );
};
